import * as Inferno from "inferno";
var createTextVNode = Inferno.createTextVNode;
var createComponentVNode = Inferno.createComponentVNode;
var createVNode = Inferno.createVNode;
var createFragment = Inferno.createFragment;
import { Fragment } from 'inferno';
import { SafetyIcon, Access, SectionSafety, SectionMaterials, SectionFreeText, SectionCleanup, PaperSize, SectionMaintenance } from './data';
import { safetyIcon2name, iconDelete } from './view_common';
function dirty() {
}
var SettingsSectionGroup = function (_a) {
    var name = _a.name, children = _a.children, _b = _a.enabled, enabled = _b === void 0 ? true : _b, onChangeEnabled = _a.onChangeEnabled;
    var enable = onChangeEnabled ? (createVNode(64, "input", null, null, 1, { "type": "checkbox", "name": "enabled", "checked": enabled, "onInput": function (e) { return onChangeEnabled(e.target.checked); } })) : null;
    return (createVNode(1, "div", "sign-section", [createVNode(1, "div", "settings-section-header", [enable, createVNode(1, "h2", null, name, 0)], 0), enabled ? children : null], 0));
};
var SettingsMaterialItem = function (_a) {
    var material = _a.material, onChange = _a.onChange, onDelete = _a.onDelete;
    return (createVNode(1, "div", "selection-row", [createVNode(64, "input", null, null, 1, { "type": "text", "list": "autocomplete-material", "placeholder": "Material name", "value": material.label, "onInput": function (e) { material.label = e.target.value; onChange(); } }), createVNode(1, "button", null, createVNode(1, "img", "invert", null, 1, { "src": iconDelete }), 2, { "onClick": onDelete, "tabIndex": -1 })], 4));
};
var SettingsSectionMaterials = function (_a) {
    var section = _a.section, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "name": section.defaultHeader(), "enabled": section.enabled, "onChangeEnabled": function (v) { section.enabled = v; onChange(); }, "children": [section.materials.map(function (v) { return SettingsMaterialItem({ material: v, onChange: onChange, onDelete: function () { removeFromArray(section.materials, v); onChange(); } }); }), createVNode(1, "button", null, "Add material", 16, { "onClick": function () { section.materials.push({ label: "" }); onChange(); } })] }));
};
var SettingsSectionFreeText = function (_a) {
    var section = _a.section, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "enabled": section.enabled, "name": section.header(), "onChangeEnabled": function (v) { section.enabled = v; onChange(); }, "children": createVNode(128, "textarea", null, null, 1, { "placeholder": "Contents...", "value": section.contents, "onInput": function (e) { section.contents = e.target.value; onChange(); } }) }));
};
var SettingsSectionMaintenance = function (_a) {
    var section = _a.section, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "name": section.defaultHeader(), "enabled": section.enabled, "onChangeEnabled": function (v) { section.enabled = v; onChange(); }, "children": [section.rows.map(function (item) { return SettingsMaintenanceItem({ item: item, onChange: onChange, onDelete: function () { removeFromArray(section.rows, item); onChange(); } }); }), createVNode(1, "button", null, "Add maintenance item", 16, { "onClick": function () { section.rows.push({ label: "", interval: "Yearly" }); onChange(); } })] }));
};
var SettingsMaintenanceItem = function (_a) {
    var item = _a.item, onChange = _a.onChange, onDelete = _a.onDelete;
    return (createVNode(1, "div", "selection-row", [createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Description", "value": item.label, "onInput": function (e) { item.label = e.target.value; onChange(); } }), createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Interval", "value": item.interval, "onInput": function (e) { item.interval = e.target.value; onChange(); } }), createVNode(1, "button", null, createVNode(1, "img", "invert", null, 1, { "src": iconDelete }), 2, { "onClick": onDelete, "tabIndex": -1 })], 4));
};
var SettingsSafetyItem = function (_a) {
    var item = _a.item, onChange = _a.onChange, onDelete = _a.onDelete;
    var safetyIcons = Object.keys(SafetyIcon).map(function (k) { return SafetyIcon[k]; }).filter(function (k) { return typeof k === "number"; });
    return (createVNode(1, "div", "selection-row", [createVNode(256, "select", null, safetyIcons.map(function (v) { return (createVNode(1, "option", null, safetyIcon2name[v], 0, { "value": v })); }), 0, { "value": item.icon, "onInput": function (e) { item.icon = Number(e.target.value); onChange(); } }), createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": safetyIcon2name[item.icon], "value": item.label, "onInput": function (e) { item.label = e.target.value; onChange(); } }), createVNode(1, "button", null, createVNode(1, "img", "invert", null, 1, { "src": iconDelete }), 2, { "onClick": onDelete, "tabIndex": -1 })], 4));
};
function removeFromArray(arr, item) {
    var i = arr.indexOf(item);
    if (i != -1)
        arr.splice(i, 1);
}
var SettingsSectionSafety = function (_a) {
    var section = _a.section, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "name": section.defaultHeader(), "enabled": section.enabled, "onChangeEnabled": function (v) { section.enabled = v; onChange(); }, "children": [section.icons.map(function (item) { return SettingsSafetyItem({ item: item, onChange: onChange, onDelete: function () { removeFromArray(section.icons, item); onChange(); } }); }), createVNode(1, "button", null, "Add safety icon", 16, { "onClick": function () { section.icons.push({ icon: SafetyIcon.SafetyGlasses, label: "" }); onChange(); } })] }));
};
var SettingsCleanupItem = function (_a) {
    var item = _a.item, onChange = _a.onChange, onDelete = _a.onDelete;
    return (createVNode(1, "div", "selection-row", [createVNode(64, "input", null, null, 1, { "type": "text", "list": "autocomplete-cleanup", "placeholder": "Cleanup task", "value": item.label, "onInput": function (e) { item.label = e.target.value; onChange(); } }), createVNode(1, "button", null, createVNode(1, "img", "invert", null, 1, { "src": iconDelete }), 2, { "onClick": onDelete, "tabIndex": -1 })], 4));
};
var SettingsSectionCleanup = function (_a) {
    var section = _a.section, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "name": section.defaultHeader(), "enabled": section.enabled, "onChangeEnabled": function (v) { section.enabled = v; onChange(); }, "children": [section.items.map(function (item) { return SettingsCleanupItem({ item: item, onChange: onChange, onDelete: function () { removeFromArray(section.items, item); onChange(); } }); }), createVNode(1, "button", null, "Add cleanup item", 16, { "onClick": function () { section.items.push({ label: "" }); onChange(); } })] }));
};
var accessMessage = {};
accessMessage[Access.CourseRequired] = "You must complete a course to use this machine";
accessMessage[Access.UsableByEveryone] = "All members may use this machine";
accessMessage[Access.UsableByEveryoneCareful] = "All members may use this machine if it can be done in a safe way";
var SignHeader = function (_a) {
    var sign = _a.sign, onChange = _a.onChange;
    var model = sign.model ? (createVNode(1, "span", null, [createTextVNode("Model: "), sign.model], 0, { "id": "machine-model" })) : null;
    var accessLevels = Object.keys(Access).map(function (k) { return Access[k]; }).filter(function (k) { return typeof k === "number"; });
    return (createComponentVNode(2, SettingsSectionGroup, { "name": "Machine", "children": [createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Machine name", "value": sign.name, "onInput": function (e) { sign.name = e.target.value; onChange(); } }), createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Machine model", "value": sign.model, "onInput": function (e) { sign.model = e.target.value; onChange(); } }), createVNode(256, "select", null, accessLevels.map(function (v) { return (createVNode(1, "option", null, accessMessage[v], 0, { "value": v })); }), 0, { "value": sign.access, "onInput": function (e) { sign.access = Number(e.target.value); onChange(); } }), createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Course URL", "value": sign.courseURL, "onInput": function (e) { sign.courseURL = e.target.value; onChange(); } })] }));
};
var SignOutOfOrder = function (_a) {
    var sign = _a.sign, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "enabled": sign.outOfOrder, "name": "Out Of Order", "onChangeEnabled": function (v) { sign.outOfOrder = v; onChange(); }, "children": createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Reason...", "value": sign.outOfOrderReason, "onInput": function (e) { sign.outOfOrderReason = e.target.value; onChange(); } }) }));
};
function setSlackChannel(sign, channel) {
    sign.slackChannel = channel.replace(/#/g, "");
}
function setWikiURL(sign, url) {
    sign.wikiURL = url.trim();
}
var SettingsSignMeta = function (_a) {
    var sign = _a.sign, onChange = _a.onChange;
    var paperSizes = Object.keys(PaperSize).map(function (k) { return PaperSize[k]; }).filter(function (k) { return typeof k === "number"; });
    return (createComponentVNode(2, SettingsSectionGroup, { "name": "Sign", "children": createVNode(256, "select", null, paperSizes.map(function (v) { return (createVNode(1, "option", null, PaperSize[v], 0, { "value": v })); }), 0, { "value": sign.paperSize, "onInput": function (e) { sign.paperSize = Number(e.target.value); onChange(); } }) }));
};
var SettingsSignFooter = function (_a) {
    var sign = _a.sign, onChange = _a.onChange;
    return (createComponentVNode(2, SettingsSectionGroup, { "name": "Footer", "children": [createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Wiki URL", "value": sign.wikiURL, "onInput": function (e) { setWikiURL(sign, e.target.value); onChange(); } }), createVNode(64, "input", null, null, 1, { "type": "text", "placeholder": "Slack Channel", "value": sign.slackChannel, "onInput": function (e) { setSlackChannel(sign, e.target.value); onChange(); } })] }));
};
function SettingsSection(section, onChange) {
    if (section instanceof SectionMaterials)
        return SettingsSectionMaterials({ section: section, onChange: onChange });
    else if (section instanceof SectionFreeText)
        return SettingsSectionFreeText({ section: section, onChange: onChange });
    //else if (section instanceof SectionOutOfOrder) return SettingsSectionOutOfOrder({ section });
    else if (section instanceof SectionSafety)
        return SettingsSectionSafety({ section: section, onChange: onChange });
    else if (section instanceof SectionCleanup)
        return SettingsSectionCleanup({ section: section, onChange: onChange });
    else if (section instanceof SectionMaintenance)
        return SettingsSectionMaintenance({ section: section, onChange: onChange });
    else
        throw new Error("Unexpected section type " + typeof (section));
}
function SettingsSave(_a) {
    var onSave = _a.onSave, onDelete = _a.onDelete, autosaved = _a.autosaved;
    return (createComponentVNode(2, SettingsSectionGroup, { "enabled": true, "name": "Save", "onChangeEnabled": null, "children": [createVNode(1, "button", null, "Delete", 16, { "onClick": onDelete }), createVNode(1, "button", null, autosaved ? "Autosaved" : "Save", 0, { "onClick": onSave })] }));
}
export var SettingsSign = function (_a) {
    var sign = _a.sign, onChange = _a.onChange, onSave = _a.onSave, onDelete = _a.onDelete, autosaved = _a.autosaved;
    var sections = sign.sections;
    var arr = [
        sections.allowedMaterials,
        sections.prohibitedMaterials,
        sections.quickStart,
        sections.safety,
        sections.cleanup,
        sections.maintenance
    ];
    return (createFragment([createComponentVNode(2, SignHeader, { "sign": sign, "onChange": onChange }), createComponentVNode(2, SettingsSignMeta, { "sign": sign, "onChange": onChange }), createComponentVNode(2, SignOutOfOrder, { "sign": sign, "onChange": onChange }), arr.map(function (s) { return SettingsSection(s, onChange); }), createComponentVNode(2, SettingsSignFooter, { "sign": sign, "onChange": onChange }), createComponentVNode(2, SettingsSave, { "onSave": onSave, "onDelete": onDelete, "autosaved": autosaved })], 0));
};
