var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var SafetyIcon;
(function (SafetyIcon) {
    SafetyIcon[SafetyIcon["HearingProtection"] = 0] = "HearingProtection";
    SafetyIcon[SafetyIcon["ProtectiveGloves"] = 1] = "ProtectiveGloves";
    SafetyIcon[SafetyIcon["SafetyGlasses"] = 2] = "SafetyGlasses";
    SafetyIcon[SafetyIcon["FaceShield"] = 3] = "FaceShield";
    SafetyIcon[SafetyIcon["GasMask"] = 4] = "GasMask";
    SafetyIcon[SafetyIcon["TightClothing"] = 5] = "TightClothing";
    SafetyIcon[SafetyIcon["GenericNo"] = 6] = "GenericNo";
    SafetyIcon[SafetyIcon["GenericOK"] = 7] = "GenericOK";
    SafetyIcon[SafetyIcon["WeldingMask"] = 8] = "WeldingMask";
})(SafetyIcon || (SafetyIcon = {}));
export var PaperSize;
(function (PaperSize) {
    PaperSize[PaperSize["A4"] = 0] = "A4";
    PaperSize[PaperSize["A5"] = 1] = "A5";
})(PaperSize || (PaperSize = {}));
export var Access;
(function (Access) {
    Access[Access["UsableByEveryone"] = 0] = "UsableByEveryone";
    Access[Access["UsableByEveryoneCareful"] = 1] = "UsableByEveryoneCareful";
    Access[Access["CourseRequired"] = 2] = "CourseRequired";
})(Access || (Access = {}));
var Sign = /** @class */ (function () {
    function Sign() {
        this.__type__ = "Sign";
        this.name = "";
        this.model = "";
        this.access = Access.UsableByEveryone;
        this.courseURL = "";
        this.outOfOrder = false;
        this.outOfOrderReason = "";
        this.wikiURL = "";
        this.slackChannel = "";
        this.paperSize = PaperSize.A4;
        this.sections = new Sections();
    }
    return Sign;
}());
export { Sign };
var Section = /** @class */ (function () {
    function Section() {
        this.__type__ = "Sign";
        this.customHeader = null;
        this.enabled = false;
    }
    Section.prototype.header = function () {
        return this.customHeader || this.defaultHeader();
    };
    return Section;
}());
export { Section };
var SectionOutOfOrder = /** @class */ (function (_super) {
    __extends(SectionOutOfOrder, _super);
    function SectionOutOfOrder() {
        var _this = _super.call(this) || this;
        _this.reason = "";
        _this.__type__ = "SignOutOfOrder";
        return _this;
    }
    SectionOutOfOrder.prototype.defaultHeader = function () {
        return "Out Of Order";
    };
    return SectionOutOfOrder;
}(Section));
export { SectionOutOfOrder };
var SectionSafety = /** @class */ (function (_super) {
    __extends(SectionSafety, _super);
    function SectionSafety() {
        var _this = _super.call(this) || this;
        _this.icons = new Array();
        _this.__type__ = "SectionSafety";
        return _this;
    }
    SectionSafety.prototype.defaultHeader = function () {
        return "Safety";
    };
    return SectionSafety;
}(Section));
export { SectionSafety };
var SectionCleanup = /** @class */ (function (_super) {
    __extends(SectionCleanup, _super);
    function SectionCleanup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.items = new Array();
        return _this;
    }
    SectionCleanup.prototype.defaultHeader = function () {
        return "Cleanup";
    };
    return SectionCleanup;
}(Section));
export { SectionCleanup };
var SectionMaterials = /** @class */ (function (_super) {
    __extends(SectionMaterials, _super);
    function SectionMaterials() {
        var _this = _super.call(this) || this;
        _this.allowed = false;
        _this.materials = new Array();
        _this.__type__ = "SectionMaterials";
        return _this;
    }
    SectionMaterials.prototype.defaultHeader = function () {
        return this.allowed ? "Allowed Materials" : "Prohibited Materials";
    };
    return SectionMaterials;
}(Section));
export { SectionMaterials };
var SectionFreeText = /** @class */ (function (_super) {
    __extends(SectionFreeText, _super);
    function SectionFreeText() {
        var _this = _super.call(this) || this;
        _this.contents = "";
        _this.__type__ = "SectionFreeText";
        return _this;
    }
    SectionFreeText.prototype.defaultHeader = function () {
        return "";
    };
    return SectionFreeText;
}(Section));
export { SectionFreeText };
var SectionMaintenance = /** @class */ (function (_super) {
    __extends(SectionMaintenance, _super);
    function SectionMaintenance() {
        var _this = _super.call(this) || this;
        _this.rows = [];
        _this.__type__ = "SectionMaintenance";
        return _this;
    }
    SectionMaintenance.prototype.defaultHeader = function () {
        return "Maintenance";
    };
    return SectionMaintenance;
}(Section));
export { SectionMaintenance };
var Sections = /** @class */ (function () {
    function Sections() {
        this.allowedMaterials = new SectionMaterials();
        this.prohibitedMaterials = new SectionMaterials();
        this.safety = new SectionSafety();
        this.cleanup = new SectionCleanup();
        this.quickStart = new SectionFreeText();
        this.outOfOrder = new SectionOutOfOrder();
        this.maintenance = new SectionMaintenance();
        this.allowedMaterials.allowed = true;
        this.prohibitedMaterials.allowed = false;
        this.quickStart.customHeader = "Quick Start";
    }
    return Sections;
}());
export { Sections };
