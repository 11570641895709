var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Inferno from "inferno";
var createComponentVNode = Inferno.createComponentVNode;
var createVNode = Inferno.createVNode;
import './style.scss';
import { JSON } from "ta-json";
import { render, Component } from 'inferno';
import { PreviewSign } from './view_preview';
import { Sign } from './data';
import "classcat";
import { SettingsSign } from './view_settings';
import { debounce } from 'ts-debounce';
function initializeWithJson(obj, json) {
    // TODO: Handle list
    for (var prop in obj) {
        if (!json.hasOwnProperty(prop)) {
            continue;
        }
        console.assert(typeof obj[prop] === typeof json[prop]);
        if (typeof obj[prop] === 'object' && !Array.isArray(obj[prop])) {
            initializeWithJson(obj[prop], json[prop]);
        }
        else {
            obj[prop] = json[prop];
        }
    }
}
function SignItem(_a) {
    var item = _a.item, onOpen = _a.onOpen;
    return (createVNode(1, "li", null, createVNode(1, "span", null, item.name, 0), 2, { "onClick": onOpen }));
}
var SignSelector = /** @class */ (function (_super) {
    __extends(SignSelector, _super);
    function SignSelector(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { signs: [] };
        return _this;
    }
    SignSelector.prototype.componentWillMount = function () {
        this.findSigns();
    };
    SignSelector.prototype.findSigns = function () {
        var _this = this;
        fetch("data/signs").then(function (response) {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error("Error " + response.status);
            }
        }).then(function (json) {
            _this.setState({ signs: json.data });
        });
    };
    SignSelector.prototype.render = function () {
        var _this = this;
        return (createVNode(1, "div", "sign-selector", [createVNode(1, "h1", null, "MakerSign", 16), createVNode(1, "ul", null, createVNode(1, "li", null, createVNode(1, "span", null, "Create new sign", 16), 2, { "onClick": function () { return _this.props.onOpen(null); } }), 2), createVNode(1, "ul", null, this.state.signs.map(function (item) { return SignItem({ item: item, onOpen: function () { return _this.props.onOpen(item.id); } }); }), 0)], 4));
    };
    return SignSelector;
}(Component));
var SignSelectorSmall = /** @class */ (function (_super) {
    __extends(SignSelectorSmall, _super);
    function SignSelectorSmall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SignSelectorSmall.prototype.componentDidUpdate = function (lastProps, lastState, snapshot) {
        if (lastProps.selectedId != this.props.selectedId) {
            this.findSigns();
        }
    };
    SignSelectorSmall.prototype.render = function () {
        var _this = this;
        return (createVNode(256, "select", "sign-selector", [this.state.signs.map(function (item) { return (createVNode(1, "option", null, item.name, 0, { "value": item.id })); }), createVNode(1, "option", null, "Create new sign", 16, { "value": -1 })], 0, { "value": this.props.selectedId != null ? this.props.selectedId : -1, "onInput": function (e) { var id = Number(e.target.value); _this.props.onOpen(id != -1 ? id : null); } }));
    };
    return SignSelectorSmall;
}(SignSelector));
// How often to save (at most). In milliseconds
var SavingInterval = 2000;
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.debouncedSave = debounce(function () { return _this.save(); }, SavingInterval);
        _this.state = { sign: null, id: null };
        _this.openFromURL();
        return _this;
    }
    App.prototype.onChange = function () {
        console.log("Changed");
        this.setState({});
        this.debouncedSave();
    };
    App.prototype.componentWillMount = function () {
        var _this = this;
        window.onpopstate = function () { return _this.openFromURL(); };
        // this.openFromURL();
    };
    App.prototype.openFromURL = function () {
        var matches = window.location.pathname.match(/\/(\d+)/);
        if (matches !== null) {
            this.open(Number(matches[1]), false);
        }
        else {
            this.setState({ sign: null, id: null });
        }
    };
    App.prototype.open = function (id, pushState) {
        var _this = this;
        if (pushState === void 0) { pushState = true; }
        if (this.state.id == id && id !== null)
            return;
        if (id === null) {
            // Create new
            if (this.state.id !== null && pushState) {
                history.pushState({}, "", "/");
            }
            var sign = new Sign();
            sign.name = "Test";
            this.setState({ sign: sign, id: null });
        }
        else {
            fetch("data/signs/" + id).then(function (r) { return r.json(); }).then(function (json) {
                console.log("Got response " + json);
                var item = json.data;
                var sign = new Sign();
                initializeWithJson(sign, item.data);
                _this.setState({ sign: sign, id: item.id });
                if (pushState)
                    history.pushState({}, "", "/" + id);
            }).catch(function (e) {
                console.error(e);
                _this.setState({ sign: null, id: null });
                history.pushState({}, "", "/");
            });
        }
    };
    App.prototype.save = function () {
        var _this = this;
        console.log("Saving");
        if (this.saving)
            return;
        if (this.state.sign === null)
            return;
        fetch(this.state.id !== null ? "data/signs/" + this.state.id : 'data/signs', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.sign)
        }).then(function (r) { return r.json(); }).then(function (json) {
            if (_this.state.id === null) {
                history.pushState({}, "", "/" + json.data.id);
            }
            _this.setState({ id: json.data.id });
        }).finally(function () {
            _this.saving = false;
        });
    };
    App.prototype.delete = function () {
    };
    App.prototype.render = function () {
        var _this = this;
        if (this.state.sign != null) {
            return (createVNode(1, "div", "app-root", [createVNode(1, "div", null, createVNode(1, "div", "sign-root", [createComponentVNode(2, SignSelectorSmall, { "selectedId": this.state.id, "onOpen": function (id) { return _this.open(id); } }), createComponentVNode(2, SettingsSign, { "sign": this.state.sign, "onChange": function () { return _this.onChange(); }, "onSave": function () { return _this.save(); }, "onDelete": function () { return _this.delete(); }, "autosaved": this.state.id !== null })], 4), 2, { "id": "settings" }), createVNode(1, "div", null, createComponentVNode(2, PreviewSign, { "sign": this.state.sign, "id": this.state.id }), 2, { "id": "preview" })], 4));
        }
        else {
            return (createVNode(1, "div", "app-root", createComponentVNode(2, SignSelector, { "onOpen": function (id) { return _this.open(id); } }), 2));
        }
    };
    return App;
}(Component));
export { App };
render(createComponentVNode(2, App), document.getElementById("app-root"));
