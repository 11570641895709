var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Inferno from "inferno";
var createTextVNode = Inferno.createTextVNode;
var createComponentVNode = Inferno.createComponentVNode;
var createVNode = Inferno.createVNode;
var createFragment = Inferno.createFragment;
import { Component, Fragment } from 'inferno';
import { Access, SectionSafety, SectionMaterials, SectionFreeText, SectionCleanup, PaperSize, SectionMaintenance } from './data';
import { safetyIcon2svg, safetyIcon2name, iconCleanup, ColorClass, iconAllowedMaterial, iconProhibitedMaterial } from './view_common';
import * as QRCode from 'qrcode';
var PreviewMaterial = function (material, allowed) { return (createVNode(1, "div", null, [createVNode(1, "img", "invert", null, 1, { "src": allowed ? iconAllowedMaterial : iconProhibitedMaterial }), createVNode(1, "p", null, material.label || "Unnamed Material", 0)], 0)); };
var PreviewSectionGroup = function (_a) {
    var className = _a.className, name = _a.name, children = _a.children;
    return (createVNode(1, "div", "sign-section " + className, [createVNode(1, "h2", null, name, 0), children], 0));
};
var PreviewSectionMaterials = function (_a) {
    var section = _a.section, useHorizontalList = _a.useHorizontalList;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": "sign-materials-" + (section.allowed ? "allowed" : "prohibited"), "name": section.header(), "children": createVNode(1, "div", useHorizontalList ? "item-list-horizontal-small" : "item-list", section.materials.map(function (item) { return PreviewMaterial(item, section.allowed); }), 0) }));
};
var PreviewSectionFreeText = function (_a) {
    var section = _a.section;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": "sign-quickstart", "name": section.header(), "children": createVNode(1, "p", null, section.contents, 0) }));
};
var PreviewSafetyItem = function (item) { return (createVNode(1, "div", null, [createVNode(1, "img", "invert", null, 1, { "src": safetyIcon2svg[item.icon] || "" }), createVNode(1, "p", null, item.label || safetyIcon2name[item.icon], 0)], 4)); };
var PreviewSectionSafety = function (_a) {
    var section = _a.section;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": "sign-safety", "name": section.header(), "children": createVNode(1, "div", "item-list", section.icons.map(PreviewSafetyItem), 0) }));
};
var PreviewCleanupItem = function (item) { return (createVNode(1, "div", null, [createVNode(1, "img", "invert", null, 1, { "src": iconCleanup || "" }), createVNode(1, "p", null, item.label || "Untitled task", 0)], 4)); };
var PreviewSectionMaintenance = function (_a) {
    var section = _a.section;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": "sign-maintenance", "name": section.header(), "children": createVNode(1, "div", "item-list-rows", section.rows.map(PreviewMaintenanceItem), 0) }));
};
var PreviewMaintenanceItem = function (item) { return (createVNode(1, "div", null, [createVNode(1, "span", "label", item.label || "No description", 0), createVNode(1, "span", "interval", item.interval, 0), createVNode(1, "span", "maintenance-label-space", "Last done", 16)], 4)); };
var PreviewSectionCleanup = function (_a) {
    var section = _a.section;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": "sign-cleanup", "name": section.header(), "children": createVNode(1, "div", "item-list-horizontal-small", section.items.map(PreviewCleanupItem), 0) }));
};
var SignHeader = function (_a) {
    var sign = _a.sign;
    var model = sign.model ? (createVNode(1, "span", null, [createTextVNode("Model: "), sign.model], 0, { "id": "machine-model" })) : null;
    return (createVNode(1, "div", "sign-section sign-name " + ColorClass(sign), [createVNode(1, "h1", null, sign.name, 0, { "id": "machine-name" }), model], 0));
};
var accessMessage = {};
accessMessage[Access.CourseRequired] = "You must complete a course to use this machine";
accessMessage[Access.UsableByEveryone] = "All members may use this machine";
accessMessage[Access.UsableByEveryoneCareful] = "You may use this machine if you know how to operate it and can do so safely";
var CourseQRCode = /** @class */ (function (_super) {
    __extends(CourseQRCode, _super);
    function CourseQRCode(props) {
        var _this = _super.call(this, props) || this;
        _this.lastQRUrl = null;
        _this.state = { qrData: "" };
        _this.componentDidUpdate(null, null, null);
        return _this;
    }
    CourseQRCode.prototype.componentDidUpdate = function (lastProps, nextState, context) {
        var _this = this;
        var url = this.props.sign.courseURL.trim();
        if (url != this.lastQRUrl) {
            this.lastQRUrl = url;
            if (url == "") {
                this.setState({ qrData: "" });
            }
            else {
                var opts = {
                    errorCorrectionLevel: 'M',
                    type: 'image/png',
                    margin: 1,
                    color: {
                        dark: "#000",
                        light: "#FFF"
                    }
                };
                QRCode.toDataURL(url, opts, function (e, data) {
                    // Hack: required when called from constructor
                    _this.state.qrData = data;
                    _this.setState({ qrData: data });
                });
            }
        }
    };
    CourseQRCode.prototype.render = function () {
        if (this.state.qrData != "") {
            return (createVNode(1, "div", null, [createVNode(1, "img", null, null, 1, { "src": this.state.qrData }), createVNode(1, "h3", null, "Course", 16)], 4));
        }
    };
    return CourseQRCode;
}(Component));
var SignAccess = function (_a) {
    var sign = _a.sign;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": ColorClass(sign), "name": "Access", "children": createVNode(1, "div", "sign-access", [sign.courseURL != "" ? createComponentVNode(2, CourseQRCode, { "sign": sign }) : null, createVNode(1, "span", "sign-access-label", accessMessage[sign.access], 0)], 0) }));
};
var SignOutOfOrder = function (_a) {
    var sign = _a.sign;
    var reason = sign.outOfOrderReason ? (createVNode(1, "p", null, sign.outOfOrderReason, 0)) : null;
    return (createComponentVNode(2, PreviewSectionGroup, { "className": ColorClass(sign), "name": "Status", "children": [createVNode(1, "span", "sign-access-label", "This machine is out of order", 16), reason, createVNode(1, "p", null, "See Facebook or Slack for updates", 16), createVNode(1, "div", "sign-outoforder-icons", [createVNode(1, "div", null, [createVNode(1, "img", null, null, 1, { "src": "static/images/facebook_icon.svg" }), createVNode(1, "div", null, [createVNode(1, "h3", null, "Facebook", 16), createVNode(1, "p", null, "facebook.com/groups/makerspace.se", 16)], 4)], 4), createVNode(1, "div", null, [createVNode(1, "img", null, null, 1, { "src": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/306_Slack_logo-512.png" }), createVNode(1, "div", null, [createVNode(1, "h3", null, "Slack", 16), createVNode(1, "p", null, [createTextVNode("#"), sign.slackChannel || "general"], 0)], 4)], 4)], 4)] }));
};
var PreviewSignFooter = /** @class */ (function (_super) {
    __extends(PreviewSignFooter, _super);
    function PreviewSignFooter(props) {
        var _this = _super.call(this, props) || this;
        _this.lastQRUrl = null;
        _this.state = { qrData: "" };
        _this.componentDidUpdate(null, null, null);
        return _this;
    }
    PreviewSignFooter.prototype.componentDidUpdate = function (lastProps, nextState, context) {
        var _this = this;
        var url = this.props.sign.wikiURL.trim();
        if (!url) {
            url = "http://wiki.makerspace.se";
        }
        if (url != this.lastQRUrl) {
            this.lastQRUrl = url;
            var opts = {
                errorCorrectionLevel: 'M',
                type: 'image/png',
                margin: 1,
                color: {
                    dark: "#000",
                    light: "#FFF"
                }
            };
            console.log("URL ", url);
            QRCode.toDataURL(url, opts, function (e, data) {
                // Hack: required when called from constructor
                _this.state.qrData = data;
                _this.setState({ qrData: data });
            });
        }
    };
    PreviewSignFooter.prototype.render = function () {
        console.log("Q", this.state.qrData);
        return (createFragment([createVNode(1, "div", "sign-footer", [createVNode(1, "div", null, [createVNode(1, "img", null, null, 1, { "src": this.state.qrData }), createVNode(1, "div", null, [createVNode(1, "h3", null, "Wiki", 16), createVNode(1, "p", null, this.props.sign.wikiURL ? this.props.sign.wikiURL.replace("http://", "").replace("https://", "") : "No wiki page, you should create one!", 0)], 4)], 4), createVNode(1, "div", this.props.sign.paperSize == PaperSize.A5 ? "footer-item-right" : "", [createVNode(1, "img", null, null, 1, { "src": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/306_Slack_logo-512.png" }), createVNode(1, "div", null, [createVNode(1, "h3", null, "Slack", 16), createVNode(1, "p", "pre", [createTextVNode("#"), this.props.sign.slackChannel || "general"], 0)], 4)], 4), this.props.sign.paperSize == PaperSize.A4 ? (createVNode(1, "div", null, [createVNode(1, "img", null, null, 1, { "src": "static/images/zondicons/exclamation-outline.svg" }), createVNode(1, "div", null, [createVNode(1, "h3", null, "Changes needed?", 16), createVNode(1, "p", "pre", [createTextVNode("Update this sign at"), createVNode(1, "br"), createTextVNode("sign.arongranberg.com/"), this.props.id], 0)], 4)], 4))
                    : null], 0), this.props.sign.paperSize == PaperSize.A5 ? (createVNode(1, "div", "sign-footer-tiny", createVNode(1, "p", null, [createTextVNode("Changes needed? Update this sign at sign.arongranberg.com/"), this.props.id], 0), 2)) : null], 0));
    };
    return PreviewSignFooter;
}(Component));
function PreviewSection(section, useHorizontalList) {
    if (!section.enabled)
        return;
    if (section instanceof SectionMaterials)
        return PreviewSectionMaterials({ section: section, useHorizontalList: useHorizontalList });
    else if (section instanceof SectionFreeText)
        return PreviewSectionFreeText({ section: section });
    //else if (section instanceof SectionOutOfOrder) return PreviewSectionOutOfOrder({ section });
    else if (section instanceof SectionSafety)
        return PreviewSectionSafety({ section: section });
    else if (section instanceof SectionMaintenance)
        return PreviewSectionMaintenance({ section: section });
    else if (section instanceof SectionCleanup)
        return PreviewSectionCleanup({ section: section });
    else
        throw new Error("Unexpected section type " + typeof (section));
}
export var PreviewSign = function (_a) {
    var sign = _a.sign, id = _a.id;
    if (sign.outOfOrder) {
        return (createVNode(1, "div", "sign-root", [createComponentVNode(2, SignHeader, { "sign": sign }), createComponentVNode(2, SignOutOfOrder, { "sign": sign }), createComponentVNode(2, PreviewSignFooter, { "sign": sign, "id": id })], 4));
    }
    var sections = sign.sections;
    var useHorizontalList = Math.max((sections.prohibitedMaterials.enabled ? sections.prohibitedMaterials.materials.length : 0), (sections.allowedMaterials.enabled ? sections.allowedMaterials.materials.length : 0)) > 4;
    useHorizontalList = useHorizontalList || (sections.prohibitedMaterials.enabled && sections.prohibitedMaterials.materials.some(function (v) { return v.label.length > 22; }));
    useHorizontalList = useHorizontalList || (sections.allowedMaterials.enabled && sections.allowedMaterials.materials.some(function (v) { return v.label.length > 22; }));
    var arr = [
        sections.safety,
        sections.allowedMaterials,
        sections.prohibitedMaterials,
        sections.quickStart,
        sections.cleanup,
        sections.maintenance,
    ];
    return (createVNode(1, "div", "sign-root " + (sign.paperSize == PaperSize.A4 ? "a4" : "a5"), [createComponentVNode(2, SignHeader, { "sign": sign }), createComponentVNode(2, SignAccess, { "sign": sign }), arr.map(function (section) { return PreviewSection(section, useHorizontalList); }), createComponentVNode(2, PreviewSignFooter, { "sign": sign, "id": id })], 0));
};
